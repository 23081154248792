import React, { useState } from "react";
import { Link } from "gatsby";
import { MdMenu } from "react-icons/md";

import {
  siteHeader,
  navLink,
  homeButton,
  mobileButton,
  mobileIcon,
  onScreen,
  offScreen,
} from "./Header.module.scss";

const Header = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  return (
    <header className={`${siteHeader}`}>
      <div className="grid-wrapper">
        <nav>
          <div className={`${navLink} ${homeButton}`}>
            <Link to="/" aria-label="Return to home page">
              YB
            </Link>
          </div>
          <ul className={mobileMenuVisible ? onScreen : offScreen}>
            <li className={navLink}>
              <Link to="/about">About</Link>
            </li>
            <li className={navLink}>
              <Link to="/projects">Projects</Link>
            </li>
            <li className={navLink}>
              <Link to="/contact">Contact</Link>
            </li>
            <li className={navLink}>
              <Link to="/my-writings">My Writings</Link>
            </li>
          </ul>
        </nav>
        <button
          className={mobileButton}
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
        >
          <MdMenu className={mobileIcon} />
        </button>
      </div>
    </header>
  );
};

export default Header;
