import React from "react";

import { footer } from './Footer.module.scss';

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className={`grid-wrapper ${footer}`}>
      <p>Yolanda Bonnell ©{currentYear}</p>
    </footer>
  );
};

export default Footer;
