import * as React from "react"
import Layout from "../components/Layout/Layout"

import { hero } from './404.module.scss';

const NotFoundPage = () => {
  return (
    <Layout>
      <section className={hero}>
        <h1>Page not found</h1>
        <p>Very sorry but the page you are looking for doesn't seem to exist. You can try looking at my upcoming projects to see if the show you are looking for is there, or you can contact me directly with your question!</p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
